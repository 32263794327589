.active {
  align-items: center;
  background-color: var(--color-highlight);
  border: 2px solid var(--color-highlight) !important;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.inactive {
  background-color: var(--color-websiteBackground);
  border: 2px solid var(--color-separator) !important;
  border-radius: 4px;
  box-sizing: border-box;
  flex-shrink: 0;
  height: 24px;
  width: 24px;
}