.button {
  padding: 16px 32px;
}

.button:active {
  background-color: var(--color-separatorWithShadow);
}

@media (hover: hover) {
  .button:hover {
    background-color: var(--color-separatorWithShadow);
  }
}