
/* Keep in sync with BackgroundOverlay.module.css */
@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.modal::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.button {
  display: flex;
  justify-content: center;
  margin-top: 32px;
}

.closeRow {
  background-color: transparent;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: -36px;
  left: 0px;
}

.container {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.containerInner {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  max-height: 95%;
  max-width: 90%;
  position: relative;
  z-index: var(--z-index-modal);
}

.description {
  margin-bottom: 24px;
}

.descriptionAndContent {
  box-sizing: border-box;
  /* 81px is height of title and close button */
  max-height: calc(95vh - 81px);
  overflow: auto;
  padding: 64px;
  padding-bottom: 48px;
  padding-top: 32px;
}

.footer {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

.modal {
  background-color: var(--color-white);
  border-radius: 12px;
  box-sizing: border-box;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.outsideClick {
  border-radius: 12px;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.title {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  row-gap: 24px;
}

.titleAndCloseButton {
  align-items: center;
  border-bottom: 1px solid var(--color-separator);
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  width: 100%;
}

@media only screen and (max-width: 1800px) {
  .descriptionAndContent {
    padding-bottom: 48px;
  }
}

@media only screen and (max-width: 1024px) {
  .descriptionAndContent {
    padding-bottom: 40px;
  }
}

@media only screen and (max-width: 640px) {
  .footer {
    margin-bottom: 30px;
  }

  .descriptionAndContent {
    padding: 30px 24px 30px;
  }

  .titleAndClose {
    column-gap: 20px;
    padding: 0px 16px;
  }
}
