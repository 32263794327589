.closeButton {
  align-self: center;
  background-color: transparent;
  background-position: center;
  background-repeat: no-repeat;
  border-style: none;
  outline: none;
  padding: 0px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* Icon styles */

.closeButton20Ghost {
  background-image: url("/icons/cross-20-ghost.svg");
}

.closeButton24Secondary {
  background-image: url("/icons/cross-24-secondary.svg");
}

.closeButton32Secondary {
  background-image: url("/icons/cross-32-secondary.svg");
}

/* Active */
.closeButton:active {
  background-color: var(--color-lightPurpleHover);
  cursor: pointer;
}

.closeButton20Ghost:active {
  background-image: url("/icons/cross-20-highlight.svg");
}

.closeButton24Secondary:active {
  background-image: url("/icons/cross-24-highlight.svg");
}

.closeButton32Secondary:active {
  background-image: url("/icons/cross-32-highlight.svg");
}

/* Hover */
@media (hover: hover) {
  .closeButton:hover {
    background-color: var(--color-lightPurpleHover);
    cursor: pointer;
  }

  .closeButton20Ghost:hover {
    background-image: url("/icons/cross-20-highlight.svg");
  }

  .closeButton24Secondary:hover {
    background-image: url("/icons/cross-24-highlight.svg");
  }

  .closeButton32Secondary:hover {
    background-image: url("/icons/cross-32-highlight.svg");
  }
}