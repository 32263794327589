.button {
  align-items: center;
  border-radius: 100px;
  border-style: none;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  padding: 16px;
  outline: none;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}

.button:disabled,
.disabled {
  cursor: default;
  opacity: 0.48;
  pointer-events: none;
}

.buttonAutoWidth {
  padding: 16px 32px;
}

.boxShadowDark {
  box-shadow: var(--box-shadow-dark);
}

.boxShadowNormal {
  box-shadow: var(--box-shadow-button);
}

.button:enabled:hover {
  cursor: pointer;
}

.icon {
  /* Fixes https://github.com/vercel/next.js/issues/21914 */
  font-size: 0px;
  line-height: 0px;
}

.iconLeft {
  margin-right: 10px;
}

.iconRight {
  margin-left: 10px;
}

.hideChildren {
  height: 0;
  overflow-y: hidden;
}

.linkContent:hover {
  cursor: pointer;
}

.linkInternal {
  /* Otherwise, height gets messed up */
  display: flex;
  text-decoration: none;
}

/*
 * Themes
 */

.outlineTheme {
  /* Account for border */
  padding-bottom: 14px;
  padding-top: 14px;
}

/* Error theme */

.errorTheme,
.errorThemeLink {
  background-color: var(--color-error);
  color: var(--color-white);
}

.errorTheme:enabled:active,
.errorThemeLink:active {
  background-color: var(--color-errorHover);
}

@media (hover: hover) {
  .errorTheme:enabled:hover,
  .errorThemeLink:hover {
    background-color: var(--color-errorHover);
  }
}

/* Highlight theme */

.highlightTheme,
.highlightThemeLink {
  background-color: var(--color-highlight);
  color: var(--color-white);
}

.highlightTheme:enabled:active,
.highlightThemeLink:active {
  background-color: var(--color-highlightHover);
}

@media (hover: hover) {
  .highlightTheme:enabled:hover,
  .highlightThemeLink:hover {
    background-color: var(--color-highlightHover);
  }
}

/* Highlight outline theme */


.highlightOutlineTheme,
.highlightOutlineThemeLink {
  background-color: transparent;
  border: 2px solid var(--color-highlight);
  color: var(--color-highlight);
}

.highlightOutlineTheme:enabled:active,
.highlightOutlineThemeLink:active {
  background-color: var(--color-highlight);
  color: var(--color-white);
}

.highlightOutlineTheme:enabled:active path,
.highlightOutlineThemeLink:active path {
  stroke: var(--color-white);
}

@media (hover: hover) {
  .highlightOutlineTheme:enabled:hover,
  .highlightOutlineThemeLink:hover {
    background-color: var(--color-highlight);
    color: var(--color-white);
  }

  .highlightOutlineTheme:enabled:hover path,
  .highlightOutlineThemeLink:hover path {
    stroke: var(--color-white);
  }
}


/* Mauve theme */

.mauveTheme, .mauveThemeLink {
  background-color: var(--color-mauve);
  color: var(--color-white);
}

.mauveTheme:enabled:active,
.mauveThemeLink:active {
  background-color: var(--color-mauveHover);
}

@media (hover: hover) {
  .mauveTheme:enabled:hover,
  .mauveThemeLink:hover {
    background-color: var(--color-mauveHover);
  }
}

/* Primary theme */

.primaryTheme,
.primaryThemeLink {
  background-color: var(--color-primary);
  color: var(--color-white);
}

.primaryTheme:enabled:active,
.primaryThemeLink:active {
  background-color: var(--color-secondary);
}

@media (hover: hover) {
  .primaryTheme:enabled:hover,
  .primaryThemeLink:hover {
    background-color: var(--color-secondary);
  }
}

/* Separator theme */

.separatorTheme,
.separatorThemeLink {
  background-color: var(--color-separator);
  color: var(--color-primary);
}

.separatorTheme:enabled:active,
.separatorThemeLink:active {
  background-color: var(--color-separatorHover);
}

@media (hover: hover) {
  .separatorTheme:enabled:hover,
  .separatorThemeLink:hover {
    background-color: var(--color-separatorHover);
  }
}

@media only screen and (max-width: 640px) {
  .button,
  .buttonAutoWidth {
    padding: 12px 20px;
  }

  .outlineTheme {
    /* Account for border */
    padding-bottom: 10px;
    padding-top: 10px;
  }
}